import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, importProvidersFrom, inject, isDevMode, provideAppInitializer, type ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withEnabledBlockingInitialNavigation, withPreloading } from '@angular/router';
import { provideNodeBigError } from '@big-direkt/error-handling';
import { FormViewModule } from '@big-direkt/form/view';
import { provideNodeBigAccordion, provideNodeBigAccordionItem, provideNodeBigArticle, provideNodeBigForm, provideNodeBigLegal } from '@big-direkt/nodes';
import { provideAccordionParagraph } from '@big-direkt/paragraphs/accordion';
import { provideButtonParagraph } from '@big-direkt/paragraphs/button';
import { provideC2aBannerParagraph } from '@big-direkt/paragraphs/c2a-banner';
import { provideCardGroupParagraph } from '@big-direkt/paragraphs/card-group';
import { provideHeadingParagraph } from '@big-direkt/paragraphs/heading';
import { provideImageParagraph } from '@big-direkt/paragraphs/image';
import { provideInstructionParagraph } from '@big-direkt/paragraphs/instruction';
import { provideLinkListParagraph } from '@big-direkt/paragraphs/link-list';
import { provideListParagraph } from '@big-direkt/paragraphs/list';
import { provideQuickLinkListParagraph } from '@big-direkt/paragraphs/quick-link-list';
import { provideSearchableLinkListParagraph } from '@big-direkt/paragraphs/searchable-link-list';
import { provideTableParagraph } from '@big-direkt/paragraphs/table';
import { provideTextParagraph } from '@big-direkt/paragraphs/text';
import { provideVideoGroupParagraph } from '@big-direkt/paragraphs/video-group';
import { provideWebformParagraph } from '@big-direkt/paragraphs/webform';
import { RestApiClientModule } from '@big-direkt/rest-api-client';
import { initialState as MobileAppInitialState, MobileAppRepository, mobileAppStore } from '@big-direkt/state/mobile-app';
import { uiStore } from '@big-direkt/state/ui';
import { UserModule, userStore } from '@big-direkt/state/user';
import {
    CustomErrorHandlerService,
    EnvironmentService,
    FeatureFlagsService,
    LogSentryInterceptor,
    STATIC_ENVIRONMENT,
    featureFlagsStore,
    responseInterceptor,
} from '@big-direkt/utils/environment';
import { TranslocoLoaderService } from '@big-direkt/utils/i18n';
import { provideTransloco } from '@jsverse/transloco';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { NgOverlayContainerModule } from 'ng-overlay-container';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { MobileAppVersionHeaderInterceptor } from './mobile-app-version-header-interceptor';

registerLocaleData(localeDE);

MobileAppInitialState.mobileApp.isEmbeddedInMobileApp = true;

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        provideAppInitializer(async () => {
            const initializerFn = (
                (environmentService: EnvironmentService, featureFlagService: FeatureFlagsService): (() => Promise<void>) =>
                    async () =>
                        firstValueFrom(environmentService.load()).then(
                        // eslint-disable-next-line arrow-body-style
                            async () =>
                            // eslint-disable-next-line arrow-body-style, sonarjs/no-nested-functions
                                firstValueFrom(featureFlagService.load()).then(() => {
                                    return;
                                }),
                        )
            )(inject(EnvironmentService), inject(FeatureFlagsService));

            return initializerFn();
        }),
        { provide: STATIC_ENVIRONMENT, useValue: environment },

        provideAnimations(),
        provideHttpClient(withInterceptors([responseInterceptor]), withFetch(), withInterceptorsFromDi()),
        provideTransloco({
            config: {
                availableLangs: ['de'],
                defaultLang: 'de',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoLoaderService,
        }),
        provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withPreloading(PreloadAllModules)),

        importProvidersFrom(RestApiClientModule),
        importProvidersFrom(FormViewModule),
        importProvidersFrom(NgOverlayContainerModule),
        importProvidersFrom(UserModule),

        {
            provide: 'persistStorage',
            useValue: persistState(featureFlagsStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(userStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(mobileAppStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(uiStore, {
                key: 'app',
                storage: sessionStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MobileAppVersionHeaderInterceptor,
            multi: true,
            deps: [MobileAppRepository],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogSentryInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandlerService,
        },

        // Nodes
        provideNodeBigAccordion(),
        provideNodeBigAccordionItem(),
        provideNodeBigArticle(),
        provideNodeBigError(),
        provideNodeBigForm(),
        provideNodeBigLegal(),

        // Paragraphs
        provideAccordionParagraph(),
        provideButtonParagraph(),
        provideC2aBannerParagraph(),
        provideCardGroupParagraph(),
        provideHeadingParagraph(),
        provideImageParagraph(),
        provideInstructionParagraph(),
        provideLinkListParagraph(),
        provideListParagraph(),
        provideQuickLinkListParagraph(),
        provideSearchableLinkListParagraph(),
        provideTableParagraph(),
        provideTextParagraph(),
        provideVideoGroupParagraph(),
        provideWebformParagraph(),
    ],
};
